import { i18n } from "@lingui/core";
import { useState, MouseEvent, useEffect } from "react";
import { Popper, Button, Paper, Typography, Box, Fade, SvgIcon, Link } from "@mui/material";
import { useIsPath, useMobile } from "../../hooks";
import { Trans, t } from "@lingui/macro";
import { addresses } from "src/constants";
import { useWeb3Context } from "src/hooks/web3Context";

import FlagIcon from "../../helpers/flagicon.js";
import { locales, selectLocale } from "../../locales";
import { ReactComponent as ArrowDownIcon } from "src/assets/icons/arrow-down.svg";

function getLocaleFlag(locale) {
  return locales[locale].flag;
}

function MenuDrop({ mobileOpen, handleDrawerToggle }) {
  const { isSmallScreen } = useMobile();
  const { chainID } = useWeb3Context();

  const id = "menu-popper";
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentTitle, setCurrentTitle] = useState("Origin");
  const open = Boolean(anchorEl);
  const isHome = useIsPath("/home");
  const isDashboard = useIsPath("/dashboard");
  const isBond = useIsPath("/bonds");
  const isStake = useIsPath("/stake");
  const isReward = useIsPath("/reward");
  const isCommunity = useIsPath("/community");
  const isInvite = useIsPath("/invite");
  const isTurbine = useIsPath("/turbine");
  const isMint = useIsPath("/mint");
  const isWebKey = useIsPath("/webkey");
  const isEcosystem = useIsPath("/ecosystem");
  // const isSwap = useIsPath("/swap");

  useEffect(() => {
    setCurrentTitle(
      isHome
        ? t`Home`
        : isDashboard
        ? t`Dashboard`
        : isBond
        ? t`Bond`
        : isStake
        ? t`Stake`
        : isReward
        ? t`Reward Pool`
        : isCommunity
        ? t`Community`
        : isInvite
        ? t`Invite`
        : isTurbine
        ? t`Turbine`
        : isMint
        ? t`Mint`
        : isEcosystem
        ? t`Ecosystem`
        : "Origin",
    );
  }, [
    isHome,
    isDashboard,
    isBond,
    isStake,
    isReward,
    isCommunity,
    isInvite,
    isTurbine,
    isMint,
    isWebKey,
    isEcosystem,
    // isSwap,
    setCurrentTitle,
  ]);

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  useEffect(() => {
    if (!mobileOpen) {
      setAnchorEl(null);
      // handleDrawerToggle();
    }
  }, [mobileOpen]);

  return (
    <Box
      component="div"
      onMouseEnter={isSmallScreen ? null : handleClick}
      onMouseLeave={isSmallScreen ? null : handleClick}
      onClick={isSmallScreen ? handleClick : null}
      display="flex"
      alignItems="center"
      justifyContent={isSmallScreen ? "flex-start" : "center"}
      sx={{
        width: isSmallScreen ? "100%" : "auto",
        borderRadius: isSmallScreen ? "8px" : "0",
        border: isSmallScreen ? "1px solid #303437" : "none",
        background: isSmallScreen ? "linear-gradient(180deg, #131418 0%, #242528 100%)" : "transparent",
        height: isSmallScreen ? "44px" : "auto",
        mb: isSmallScreen ? 1 : 0,
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent={"center"}
        sx={{
          mr: isSmallScreen ? 0 : 2,
          width: isSmallScreen ? "100%" : "auto",
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent={isSmallScreen ? "space-between" : "center"}
          sx={{
            cursor: isSmallScreen ? "none" : "pointer",
            width: isSmallScreen ? "100%" : "auto",
            pl: isSmallScreen ? 1.255 : 0,
            pr: isSmallScreen ? 2.5 : 0,
          }}
        >
          <Typography
            sx={{
              color: "#B50C05",
              fontSize: "13px",
              textTransform: "uppercase",
              mr: 1,
            }}
          >
            {currentTitle}
          </Typography>
          <SvgIcon
            viewBox="0 0 15 8"
            sx={{
              width: "15px",
            }}
            component={ArrowDownIcon}
            htmlColor="#fff"
          />
        </Box>

        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement={isSmallScreen ? "bottom" : "bottom-start"}
          transition
          sx={{ zIndex: "1312 !important" }}
        >
          {/* <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom" transition sx={{ zIndex: "1112" }}> */}
          {({ TransitionProps }) => {
            return (
              <Fade {...TransitionProps} timeout={100}>
                <Paper
                  className="header-menu"
                  elevation={1}
                  sx={{
                    borderRadius: "8px",
                    border: "2.4px solid rgba(28, 30, 33, 0.60)",
                    background: "linear-gradient(136deg, #363B40 9.64%, #1D1E23 89.75%)",
                    boxShadow: "2px 4px 9px 0px #070707, -3px -2px 16px 0px #455059",
                  }}
                >
                  <Box
                    component="div"
                    sx={{
                      borderRadius: "8px",
                      background: "linear-gradient(138deg, #32373A 2.23%, #131416 97.6%)",
                      boxShadow: "0px 0px 4px 0px #33383C inset",
                    }}
                  >
                    <Box sx={{ px: 1.675, py: 1 }}>
                      <Link href="/#/home" onClick={() => setCurrentTitle("Home")}>
                        <Typography
                          sx={{
                            color: isHome ? "#B50C05" : "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "13px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                          }}
                        >
                          <Trans>Home</Trans>
                        </Typography>
                      </Link>
                    </Box>
                    <Box sx={{ px: 1.675, py: 1 }}>
                      <Link href="/#/dashboard" onClick={() => setCurrentTitle("Dashboard")}>
                        <Typography
                          sx={{
                            color: isDashboard ? "#B50C05" : "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "13px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                          }}
                        >
                          <Trans>Dashboard</Trans>
                        </Typography>
                      </Link>
                    </Box>
                    <Box sx={{ px: 1.675, py: 1 }}>
                      <Link href="/#/webkey" onClick={() => setCurrentTitle("WebKey")}>
                        <Typography
                          sx={{
                            color: isWebKey ? "#B50C05" : "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "13px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                          }}
                        >
                          <Trans>WebKey</Trans>
                        </Typography>
                      </Link>
                    </Box>
                    <Box sx={{ px: 1.675, py: 1 }}>
                      <Link href="/#/ecosystem" onClick={() => setCurrentTitle("Ecosystem")}>
                        <Typography
                          sx={{
                            color: isEcosystem ? "#B50C05" : "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "13px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                          }}
                        >
                          <Trans>Ecosystem</Trans>
                        </Typography>
                      </Link>
                    </Box>
                    <Box sx={{ px: 1.675, py: 1 }}>
                      <Link href="/#/stake" onClick={() => setCurrentTitle("Stake")}>
                        <Typography
                          sx={{
                            color: isStake ? "#B50C05" : "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "13px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                          }}
                        >
                          <Trans>Stake</Trans>
                        </Typography>
                      </Link>
                    </Box>
                    <Box sx={{ px: 1.675, py: 1 }}>
                      <Link href="/#/bonds" onClick={() => setCurrentTitle("Bond")}>
                        <Typography
                          sx={{
                            color: isBond ? "#B50C05" : "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "13px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                          }}
                        >
                          <Trans>Bond</Trans>
                        </Typography>
                      </Link>
                    </Box>
                    <Box sx={{ px: 1.675, py: 1 }}>
                      <Link href="/#/reward" onClick={() => setCurrentTitle("Reward")}>
                        <Typography
                          sx={{
                            color: isReward ? "#B50C05" : "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "13px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                          }}
                        >
                          <Trans>Reward Pool</Trans>
                        </Typography>
                      </Link>
                    </Box>
                    <Box sx={{ px: 1.675, py: 1 }}>
                      <Link href="/#/community" onClick={() => setCurrentTitle("Community")}>
                        <Typography
                          sx={{
                            color: isCommunity ? "#B50C05" : "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "13px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                          }}
                        >
                          <Trans>Community</Trans>
                        </Typography>
                      </Link>
                    </Box>
                    <Box sx={{ px: 1.675, py: 1 }}>
                      <Link href="/#/invite" onClick={() => setCurrentTitle("Invite")}>
                        <Typography
                          sx={{
                            color: isInvite ? "#B50C05" : "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "13px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                          }}
                        >
                          <Trans>Invite</Trans>
                        </Typography>
                      </Link>
                    </Box>
                    <Box sx={{ px: 1.675, py: 1 }}>
                      <Link href="/#/turbine" onClick={() => setCurrentTitle("Turbine")}>
                        <Typography
                          sx={{
                            color: isTurbine ? "#B50C05" : "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "13px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                          }}
                        >
                          <Trans>Turbine</Trans>
                        </Typography>
                      </Link>
                    </Box>
                    <Box sx={{ px: 1.675, py: 1 }}>
                      <Link href="/#/mint" onClick={() => setCurrentTitle("Mint")}>
                        <Typography
                          sx={{
                            color: isMint ? "#B50C05" : "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "13px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                          }}
                        >
                          <Trans>Mint</Trans>
                        </Typography>
                      </Link>
                    </Box>
                    <Box sx={{ px: 1.675, py: 1 }}>
                      <Link
                        href={`https://quickswap.exchange/#/swap?chain=mainnet&inputCurrency=${addresses[chainID].USDT_ADDRESS}&outputCurrency=${addresses[chainID].OHM_ADDRESS}`}
                        target="_blank"
                      >
                        {/* <Link href="/#/swap" onClick={() => setCurrentTitle("Swap")}> */}
                        <Typography
                          sx={{
                            color: "#c7c8cc",
                            textShadow: "0px 1px 0px #000",
                            fontFamily: "Neue Haas Grotesk Text Pro",
                            fontSize: "13px",
                            fontStyle: "normal",
                            fontWeight: "500",
                            textTransform: "uppercase",
                          }}
                        >
                          <Trans>Swap</Trans>
                        </Typography>
                      </Link>
                    </Box>
                  </Box>
                </Paper>
              </Fade>
            );
          }}
        </Popper>
      </Box>
    </Box>
  );
}

export default MenuDrop;
